/**
 *  微信分享公共插件
 *  不需要传platform=wechat&timestamp=的参数,其他的业务逻辑需要传
 *  var shareDataObj = {
 *      title: '标题',
 *      imgUrl: '图片地址',
 *      desc: '描述',
 *      url: '地址',
 *      titleMoment:'分享朋友圈的标题'，
 *      encode:false,
 *      pageid: '80010.v8_1',
 *      shareRecord: { // 其它，自定分享的扩展参数
 *          projuuid: '统计分享页面当前的项目uuid',
 *      },
 *      success: function() {
 *         //用户确认分享后的回调函数
 *      },
 *      cancel: function () {
 *         //用户取消分享后执行的回调函数
 *      }
 *  };
 *  init(shareDataObj);
 *
 */
import utils from '../utils';
import mmcAjax from '../mmcAjax';
import reportAnalytics from '../reportAnalytics';

let timestamp;
let shareDataObj;


function getShareUrl(n) {
    let s = shareDataObj.link;
    const bi_cf = `share.link.weiai.${shareDataObj.pageid}`;

    const flag = shareDataObj.link.indexOf('?') == -1 ? '?' : '&';
    let params = `${flag}platform=wechat&shareto=${n}&timestamp=${timestamp}&bi_cf=${bi_cf}`;
    let share_no = utils.createRndStrId();
    params += `&share_no=${share_no}`;
    if (shareDataObj.encode == true) {
        s += window.encodeURIComponent(params);
    } else {
        s += params;
    }


    return s;
}

function init(o) {
    timestamp = utils.getShareTimestamp();
    shareDataObj = {
        title: '【宠物筹】如果需要筹款，点这里立即填写筹款信息！',
        imgUrl: 'https://m.jisuchou.net/fund/sharepic.png',
        desc: '填写求助信息遇到任何问题，请在公众号联系客服',
        link: `${window.BasePath}fund/publish/guidance`,
        titleMoment: '【宠物筹】如果需要筹款，点这里立即填写筹款信息！',
        // pageid: '80010.v8_1',
        encode: false,
        shareRecord: {},
    };

    if (o) {
        // 标题
        if (o.title) {
            shareDataObj.title = o.title;
            shareDataObj.titleMoment = o.title;
        }
        // 朋友圈标题
        if (o.titleMoment) {
            shareDataObj.titleMoment = o.titleMoment;
        }
        // 图片
        if (o.imgUrl) {
            shareDataObj.imgUrl = o.imgUrl;
        }
        // 描述
        if (o.desc) {
            shareDataObj.desc = o.desc;
        }
        // 描述
        if (o.encode) {
            shareDataObj.encode = o.encode;
        }

        if (o.url) {
            shareDataObj.link = o.url;
        }
        if (o.shareRecord) {
            shareDataObj.shareRecord = o.shareRecord;
        }

        if (o.pageid) {
            shareDataObj.pageid = o.pageid;
        }
    } else {
        o = shareDataObj;
    }


    // //其它
    // if (o.other) {
    //     shareDataObj.other = o.other ? o.other : {};
    // }

    mmcAjax({
        method: 'get',
        url: `${window.APIBasePath}mmc/wechat/jssdk/ticket`,
        params: {
            url: location.href
        }
    }).then((res) => {
        /** 微信配置config */
        wx.config({
            debug: false,
            appId: res.data.appId,
            timestamp: res.data.timestamp,
            nonceStr: res.data.nonceStr,
            signature: res.data.signature,
            jsApiList: [
                'checkJsApi',
                'onMenuShareTimeline',
                'onMenuShareAppMessage',
                'onMenuShareQQ',
                'onMenuShareWeibo',
                'onMenuShareQZone',
                'hideMenuItems',
                'showMenuItems',
                'hideAllNonBaseMenuItem',
                'showAllNonBaseMenuItem',
                'translateVoice',
                'startRecord',
                'stopRecord',
                'onRecordEnd',
                'playVoice',
                'pauseVoice',
                'stopVoice',
                'uploadVoice',
                'downloadVoice',
                'chooseImage',
                'previewImage',
                'uploadImage',
                'downloadImage',
                'getNetworkType',
                'openLocation',
                'getLocation',
                'hideOptionMenu',
                'showOptionMenu',
                'closeWindow',
                'scanQRCode',
                'chooseWXPay',
                'openProductSpecificView',
                'addCard',
                'chooseCard',
                'openCard',
            ],
            openTagList: ['wx-open-launch-weapp']
        });


        wx.ready(() => {
            /** 分享给朋友圈 */
            wx.onMenuShareTimeline({
                title: shareDataObj.titleMoment,
                link: getShareUrl(1),
                imgUrl: shareDataObj.imgUrl,
                success() {
                    o.success && o.success(1);
                    shareTrackEvent(1);
                },
                cancel() {
                    o.cancel && o.cancel(1);
                },
            });

            /** 分享给朋友或者群 */
            wx.onMenuShareAppMessage({
                title: shareDataObj.title, // 分享标题
                desc: shareDataObj.desc, // 分享描述
                link: getShareUrl(2), // 分享链接
                imgUrl: shareDataObj.imgUrl, // 分享图标
                type: 'link', // 分享类型,music、video或link，不填默认为link
                dataUrl: '', // 如果type是music或video，则要提供数据链接，默认为空
                success() {
                    o.success && o.success(2);
                    shareTrackEvent(2);
                },
                cancel() {
                    o.cancel && o.cancel(2);
                },
            });

            /** 分享到QQ空间 */
            wx.onMenuShareQZone({
                title: shareDataObj.title, // 分享标
                desc: shareDataObj.desc,
                link: getShareUrl(4),
                imgUrl: shareDataObj.imgUrl,
                success() {
                    o.success && o.success(4);
                    shareTrackEvent(4);
                },
                cancel() {
                    o.cancel && o.cancel(4);
                },
            });

            /** 分享到QQ */
            wx.onMenuShareQQ({
                title: shareDataObj.title,
                desc: shareDataObj.desc,
                link: getShareUrl(5),
                imgUrl: shareDataObj.imgUrl,
                success() {
                    o.success && o.success(5);
                    shareTrackEvent(5);
                },
                cancel() {
                    o.cancel && o.cancel(5);
                },
            });

            /** 分享给QQweibo */
            wx.onMenuShareWeibo({
                title: shareDataObj.title,
                desc: shareDataObj.desc,
                link: getShareUrl(6),
                imgUrl: shareDataObj.imgUrl,
                success() {
                    o.success && o.success(6);
                    shareTrackEvent(6);
                },
                cancel() {
                    o.cancel && o.cancel(6);
                },
            });
        });

        wx.error((res) => {

        });
    });
}


/**
 * 分享成功后的统计数据方法
 * 1 朋友圈；2 朋友/群；4 QQ空间；5 QQ；6 QQ微博
 */
function shareTrackEvent(n) {
    const shareScene = getShareScene(shareDataObj.shareRecord);
    const extraInfo = {
        shareurl: getShareUrl(n),
        shareto: n + '',
    };

    reportAnalytics('H5_JSB_Share_Page', {
        FileName: shareScene,
        ExtraInfo: JSON.stringify(extraInfo),
    });
}

function getShareScene(shareRecord) {
    const defaultScene = location.href;
    if (!shareRecord || !shareRecord.scene) {
        return defaultScene;
    }

    return shareRecord.scene;
}

export default {
    init,
};