import store from 'store';
import utils from './utils';
import mmcAjax from './mmcAjax';
// import sensors from 'sa-sdk-javascript';
// import bindUserAndDevice from './bindUserAndDevice';

/**
 * 验证 token 是否在有效期内
 * @param {object} tokenObj
 */
function validateToken(tokenObj) {
    const clientTime = Date.parse(new Date()) / 1000;
    const validityTime = parseInt(tokenObj.cli_create_time, 10) + parseInt(tokenObj.expires_in, 10);
    // 提前过期的时间，腾出一个小时让用户提交表单
    let safeThreshold = 3600;


    if (validityTime - clientTime < safeThreshold) {
        return false;
    }

    return true;
}

/**
 *
 * @param params 跳转到passport的时候所需要带上的一些列参数，可选值: false 或 一个如下格式的对象调用
 * {
 *      notneedphone: 0,  //是否可以不绑手机号
 *      showskip: 1,      //是否显示跳过绑定按钮
 *      newbindtpl: 1,    //是否使用新的绑定模版
 *      bindtext: '您已成功购买【轻松互助会员卡】请立即绑定手机号完成激活' //绑定手机号界面的引导语
 * }
 * @param callback 成功回调
 */
function check(...args) {
    // console.log(1343)
    // debugger
    const rqObj = utils.getRequestParams();
    const [params, callback] = args;

    if (rqObj.wxauth) {
        const curCnt = history.length;
        const prevCnt = store.get('historyCnt');

        if (prevCnt) {
            const backNum = Number(prevCnt) - curCnt;
            store.remove('historyCnt');
            history.go(backNum);

            if (backNum < 0) {
                return;
            }
        }
    }

    const localTokenStr = store.get('Mmc-Token');
    console.log(localTokenStr)
    if (localTokenStr) {
        const tokenObj = JSON.parse(localTokenStr);
        // console.log(343433344)
        if (!validateToken(tokenObj)) {
            console.log(333);
            utils.sdkJump(params);
        } else {
            let checkUrl = `${window.APIBasePath}mmc/passport/token/verify`;
            mmcAjax({
                method: 'GET',
                url: checkUrl,
                crossDomain: true,
                showMessage: false,
            }).then((res) => {
                console.log(res, params.notneedphone)
                    // alert(res.code+'dfd')
                if (res.code == 0) {
                    //没有手机号 但是需要手机号 notneedphone=0为需要手机号
                    if (!res.data.phone && !params.notneedphone) {
                        // alert(1)
                        utils.sdkJump(params);
                    } else if (typeof callback === 'function') {
                        console.log('in auth:', res)

                        // 如果进行了登录，就发送重新绑定uuid
                        if (store.get('mmcDeviceid')) {
                            const deviceData = store.get('mmcDeviceid');
                            deviceData.uuid = res.uuid;
                            deviceData.deviceid = store.get('mmcDeviceid');

                            store.set('mmcDeviceData', deviceData);

                            // bindUserAndDevice(deviceData);
                        }

                        const userObj = {
                            code: 0,
                            data: {
                                attr: {
                                    avatar_large: res.avatar_large,
                                    avatar_thumb: res.avatar_thumb,
                                    nickname: res.nickname,
                                    phone: res.data.phone,
                                    uuid: res.uuid,
                                    user_id: res.user_id,
                                },
                            },
                            error: '',
                            msg: '',
                        };

                        /** sensorsdata bind user start */
                        if (res.uuid) {
                            sensors.login('qsc_uuid_' + res.uuid);
                        }
                        /** sensorsdata bind user end */

                        callback(userObj);
                    } else {
                        alertMsg('系统繁忙，请稍后再来！');
                    }
                } else if (res.code === 3) {

                    // alert(22)
                    utils.sdkJump(params);
                } else {
                    alertMsg(res.msg);

                }


            }).catch((res) => {
                // console.log(2333)
                if (res && res.code) {
                    if (res.code === 3) {
                        // alert(22)
                        utils.sdkJump(params);
                    } else {
                        alertMsg(res.msg);
                    }
                } else {
                    // alert(JSON.stringify(res.message))
                    alertMsg('系统繁忙,请稍后再试。');
                }
            });
        }
    } else {
        utils.sdkJump(params);
    }
}

export default {
    check,
};