/** 神策上报 **/
import sensors from 'sa-sdk-javascript';
import groupUnifiedTracking from './groupUnifiedTracking.js';

// import logTracker from './logTracker';

/**
 * 初始化神策数据 Web JS SDK
 * @link https://manual.sensorsdata.cn/sa/latest/tech_sdk_client_web_use-27724227.html
 *
 * 神策的参数含义参见
 * @link https://manual.sensorsdata.cn/sa/latest/tech_sdk_client_web_high-7549300.html
 */

var isDebugging = (location.search.indexOf('debug=1') !== -1);

sensors.init({
    server_url: isDebugging ? 'https://sensors-data.qingsongchou.com/sa?project=default'
        : 'https://sensors-data.qingsongchou.com/sa?project=production',
    show_log: isDebugging,
    source_channel: ['mp', 'cc'],
    heatmap:{},
    use_app_track: true,
    max_string_length: 5000,
    /** 设定数据发送的超时时间 */
    datasend_time: 5000,
});

sensors.registerPage({
    appname: 'H5_JSB',
});

window.sensors = sensors;
/** sensorsdata init end */

function init(dataObj) {
    /** sensorsdata track start */
    try {
        groupUnifiedTracking.init();
        const session = groupUnifiedTracking.getSession();

        sensors.registerPage({
            pageid: (dataObj && dataObj.pageid) || '',
            bi_cf: session.bi_cf || '',
            ssid: session.ssid || '',
            boid: session.boid || '',
        });
        sensors.quick('autoTrackSinglePage');
    } catch (e) {
        // if (logTracker && logTracker.jsError) {
        //     logTracker.jsError('sensorsdata error:' + e.message);
        // }
    }
    /** sensorsdata track end */
};

export default {
    init
};
