
import wxPermission from './wxPermission';

import getShareUrl from './getShareUrl';
import getShareImg from './getShareImg';
import getShareTitle from './getShareTitle';
import getShareDesc from './getShareDesc';
import model from './model';
import store from 'store';
import saUtils from 'sensors-data-utils';
import reportSaEvent from './reportSaEvent';

export default function (res) {
    // 若没有使用单独的签名接口，则需要去 auth3 读取签名数据
    wx.config({
        debug: false,
        appId: res.data.wx_js_sign.appId,
        timestamp: res.data.wx_js_sign.timestamp,
        nonceStr: res.data.wx_js_sign.nonceStr,
        signature: res.data.wx_js_sign.signature,
        jsApiList: wxPermission,
        openTagList:['wx-open-launch-weapp']
    });

    /**
     * 分享成功后的统计数据方法
     * 1 朋友圈；2 朋友/群；4 QQ空间；5 QQ；6 QQ微博
    */
    function shareTrackEvent(n) {
        const shareUrl = getShareUrl(n, res);

        /** sensors data starts */
        const sensorsData = {
            is_manager: res.data.is_manager,
            project_uuid: res.sdkObj.projuuid,
            ...res.sdkObj.shareRecord,
            shareto: n,
            shareUrl,
            pageid: res.sdkObj.pageid,
        };
        reportSaEvent(sensorsData);
        /** sensors data ends */

        saUtils.set('shared', 1);

        if (res.sdkObj.success) {
            res.sdkObj.success(n, {
                shareUrl,
            });
        }

        wx.ready(readyFunc);
    }

    function shareSuccess(shareto) {
        // 为了解决某些ios系统版本下的微信6.6.6post请求发送不成功问题，增加了延迟
        setTimeout(() => {
            shareTrackEvent(shareto);

            let o = {
                uuid:res.sdkObj.projuuid,
                device_id:store.get('godevice').device_id,
                channel:shareto
            };
            model.recordShare(o);

        }, 150);

        if (res.sdkObj.success) {
            res.sdkObj.success(shareto);
        }
    }

    wx.ready(readyFunc);


    function readyFunc() {
        /** 分享给朋友圈 */
        wx.onMenuShareTimeline({
            title: getShareTitle(res),
            link: getShareUrl(1, res),
            desc: getShareDesc(res),
            imgUrl: getShareImg(res),
            success() {
                shareSuccess(1);
            },
            cancel() {
                shareSuccess(1);
            },
        });

        /** 分享给朋友或者群 */
        wx.onMenuShareAppMessage({
            title: getShareTitle(res),
            link: getShareUrl(2, res),
            desc: getShareDesc(res),
            imgUrl: getShareImg(res),
            type: 'link', // 分享类型,music、video或link，不填默认为link
            dataUrl: '', // 如果type是music或video，则要提供数据链接，默认为空
            success() {
                shareSuccess(2);
            },
            cancel() {
                shareSuccess(2);
            },
        });

        /** 分享到QQ空间 */
        wx.onMenuShareQZone({
            title: getShareTitle(res),
            link: getShareUrl(4, res),
            desc: getShareDesc(res),
            imgUrl: getShareImg(res),
            type: 'link', // 分享类型,music、video或link，不填默认为link
            dataUrl: '', // 如果type是music或video，则要提供数据链接，默认为空
            success() {
                shareSuccess(4);
            },
            cancel() {
                shareSuccess(4);
            },
        });

        /** 分享到QQ */
        wx.onMenuShareQQ({
            title: getShareTitle(res),
            link: getShareUrl(5, res),
            desc: getShareDesc(res),
            imgUrl: getShareImg(res),
            type: 'link', // 分享类型,music、video或link，不填默认为link
            dataUrl: '', // 如果type是music或video，则要提供数据链接，默认为空
            success() {
                shareSuccess(5);
            },
            cancel() {
                shareSuccess(5);
            },
        });

        /** 分享给QQweibo */
        wx.onMenuShareWeibo({
            title: getShareTitle(res),
            link: getShareUrl(6, res),
            desc: getShareDesc(res),
            imgUrl: getShareImg(res),
            type: 'link', // 分享类型,music、video或link，不填默认为link
            dataUrl: '', // 如果type是music或video，则要提供数据链接，默认为空
            success() {
                shareSuccess(6);
            },
            cancel() {
                shareSuccess(6);
            },
        });
    }

    wx.error((err) => {
        // const userObj = store.get('godevice') ? store.get('godevice') : {};
        // const wrongObj = Object.assign({ pageid: res.sdkObj.pageid, projuuid: res.sdkObj.projuuid,trackid:window.websit_optimize_alicloudlog.debug_track_id}, { wxerror: err }, res.data.wechat, userObj);
        // logTracker.myLog('shareWrong', JSON.stringify(wrongObj));
    });
}
