import aFileName from './aFileName';
import getShareTargetUrl from './getShareTargetUrl';

export default function getShareUrl(shareto, res) {
    return `${window.BasePath}fund/project/detail?projuuid=${res.data.uuid}`;
    const s = getShareTargetUrl(shareto, res);

    var middlePage = `https://m2.qschou.com/project/detail/sharemiddle/${aFileName[Math.floor(Math.random() * 100)]}.html?middletz=`;

    var shareUrl = middlePage + encodeURIComponent(s);

    return shareUrl;
}
