/**
 * 袋鼠云统计大病关系链如果分享的时候如果用户没有登录就没有user_uuid,所以就会取
 * 袋鼠云生成的唯一标示uuid如果检测localstorage和cookie没有uuid的话我们就用袋鼠云的生成uuid的方法生成一遍存到cookie和localstorage里面

 * @author 生成算法来自袋鼠云
 * @email
 * @since  17/7/10
 */

// 对cookie的操作
var cookie = {
    get(name) {
        const nameEQ = `${name}=`;
        const ca = document.cookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) == 0) return decodeURIComponent(c.substring(nameEQ.length, c.length));
        }
        return null;
    },
    set(name, value, days, cross_subdomain, is_secure) {
        let cdomain = '';
        let expires = '';
        let secure = '';
        if (cross_subdomain) {
            const matches = document.location.hostname.match(/[a-z0-9][a-z0-9\-]+\.[a-z\.]{2,6}$/i);
            const domain = matches ? matches[0] : '';
            cdomain = ((domain) ? `; domain=.${domain}` : '');
        }
        if (days) {
            const date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            expires = `; expires=${date.toGMTString()}`;
        }
        if (is_secure) {
            secure = '; secure';
        }
        document.cookie = `${name}=${encodeURIComponent(value)}${expires}; path=/${cdomain}${secure}`;
    },
    remove(name, cross_subdomain) {
        cookie.set(name, '', -1, cross_subdomain);
    },
};

// 对localstorage的操作
var localStorage = {
    error(msg) {
        console.error(`localStorage error: ${msg}`);
    },
    get(name) {
        try {
            return window.localStorage.getItem(name);
        } catch (err) {
            localStorage.error(err);
        }
        return null;
    },
    set(name, value) {
        try {
            window.localStorage.setItem(name, value);
        } catch (err) {
            localStorage.error(err);
        }
    },
    remove(name) {
        try {
            window.localStorage.removeItem(name);
        } catch (err) {
            localStorage.error(err);
        }
    },
};

// 生成用户唯一id
const UUID = (function () {
    const T = function () {
        const d = 1 * new Date();
        let i = 0;
        while (d == 1 * new Date()) {
            i++;
        }
        return (d.toString(16) + i.toString(16)).substr(0, 12);
    };
    const R = function () {
        return Math.random().toString(16).replace('.', '').substr(-10, 10);
    };
    const UA = function (n) {
        const ua = navigator.userAgent;
        let i; let ch; let buffer = [];
        let ret = 0;

        function xor(result, byte_array) {
            let j; let
                tmp = 0;
            for (j = 0; j < byte_array.length; j++) {
                tmp |= (buffer[j] << j * 8);
            }
            return result ^ tmp;
        }
        for (i = 0; i < ua.length; i++) {
            ch = ua.charCodeAt(i);
            buffer.unshift(ch & 0xFF);
            if (buffer.length >= 4) {
                ret = xor(ret, buffer);
                buffer = [];
            }
        }
        if (buffer.length > 0) {
            ret = xor(ret, buffer);
        }

        return ret.toString(16).substr(0, 8);
    };
    return function () {
        const se = (screen.height * screen.width).toString(16).substr(0, 6);
        return (`${T()}-${R()}-${UA()}-${se}-${T()}`);
    };
}());

/** *
 * 没有useruuid的话调用devicesId的方法会得到设备id并且存localstorage和cookie和袋鼠云的流程共用
 */
export default function () {
    let devicesId = '';
    // 判断cookie或者localstorage的uuid是否存在，如果不在则新建一个
    if (cookie.get('DTAUSRID') || localStorage.get('DTAUSRID')) {
        devicesId = cookie.get('DTAUSRID') || localStorage.get('DTAUSRID');
    } else {
        devicesId = UUID();
        cookie.set('DTAUSRID', devicesId, 180, true, true);
        localStorage.set('DTAUSRID', devicesId);
    }
    return devicesId;
}
