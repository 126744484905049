/**
 * 获取分享详细描述
 * @param {object} res
 */
export default function getShareDesc(res) {
    if (!res) return '';

    const {
        data,
        sdkObj,
    } = res;

    /* 优先使用自定义的 desc 描述 */
    if (sdkObj && sdkObj.desc) {
        return sdkObj.desc;
    }

    if (!data) return '';

    return data.introduction;
}
