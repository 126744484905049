// 接入降级服务
import mmcAjax from '../mmcAjax';

const getProjInfor = (pid) => {
    const url = `${window.APIBasePath}mmc/project/share/detail/${pid}`;
    return mmcAjax({
        url,
        method: 'get',
        params: {
            curUrl: location.href
        }
    })
};

const recordShare = (o) => {
    const url = `${window.APIBasePath}mmc/project/share/add`;
    return mmcAjax({
        url,
        method: 'post',
        data: o
    })
};

// const postShare = (o) => {
//     const url = `${window.APIBasePath}relation/record/share`;
//     return qscAjax.post(url, o);
// };

export default {
    getProjInfor,
    // postShare,
    recordShare,
};
