import { utils } from '../mmcModule';

const params = utils.getRequestParams();


export default function getShareTitle(res) {
    
    return '【宠物筹】'+res.data.title
    /* 优先使用用户自定义分享标题 */
    const customTitle = res && res.sdkObj && res.sdkObj.title;
    if (customTitle) return customTitle;

    return s;

}
