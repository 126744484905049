export default function getShareImg(res) {
    return res.data.project_thumb+'?x-image-process=style/thumb.png';
    /* 优先使用用户自定义图片 */
    const customImage = res && res.sdkObj && res.sdkObj.imgUrl;
    if (customImage) return customImage;

    const s = res.data.project_image;
    if (s) {
        return s.replace('@!love.png', '@!thumb.png');
    }

    return 'https://static2.qschou.com/img/v8/common/logo.png';
}
