import store from 'store';
import axios from 'axios';

// import alertMsg from './alert-msg';


const headers = {
    'Content-Type': 'application/json; charset=utf-8',
    Platform: 'mmc_h5',
};
const mmcAjax = (params) => {
    let ajObj = axios.create({
        withCredentials: true,
        timeout: 10000,
        headers,
        showMessage: true,
        xsrfCookieName: null,
    });

    ajObj.interceptors.request.use((config) => {
        const newConfig = config;
        newConfig.params = config.params || {};

        const tokenStringAll = store.get('Mmc-Token');
        if (tokenStringAll) {
            newConfig.headers['Mmc-Token'] = JSON.parse(store.get('Mmc-Token')).access_token;
        }

        if (config.method.toLowerCase() === 'get') {
            newConfig.params = config.params || {};
            newConfig.params.r = Math.random();
        } else if (
            config.method.toLowerCase() === 'post' ||
            config.method.toLowerCase() === 'put' ||
            config.method.toLowerCase() === 'patch'
        ) {
            newConfig.data = JSON.stringify(config.data);
        }
        return newConfig;
    });



    ajObj.interceptors.response.use((res) => {
        const { showMessage } = res.config;

        if (res.data.code && res.data.code !== 0) {
            if (showMessage) {
                alertMsg(res.data.msg);
            }
        }

        return res.data;
    }, (res) => {
        let showMessage;
        if (res.config && res.config.showMessage) {
            ({ showMessage } = res.config);
        }

        if (showMessage) {
            if (res.response) {
                alertMsg(`错误码：${res.response.status}\n错误内容：${res.response.statusText}`);
            } else {
                alertMsg('系统繁忙，请稍后再试');
            }
        }

        return Promise.reject(res);
    });



    return ajObj(params);
}

export default mmcAjax;
