// 本文件用作集团统一埋点
// https://qscwiki001.qingsongchou.com/pages/viewpage.action?pageId=71359531
import utils from './utils.js';
import qsUtil from './qsUtil.js';

// 创建 ID 的函数
const createId = utils.createRndStrId;

// 获取并更新集团统一埋点数据
function init() {
    const params = utils.getRequestParams();

    if (params['bi_cf']) {
        setItem('bi_cf', params['bi_cf']);
    }

    if (params['ssid']) {
        setItem('ssid', params['ssid']);
    }

    if (!getItem('ssid')) {
        setItem('ssid', createId());
    }

    if (!getItem('boid')) {
        setItem('boid', createId());
    }
}

function setItem(key, value) {
    if (!value) return;
    sessionStorage.setItem(key, value);
}

function getItem(key) {
    return sessionStorage.getItem(key) || '';
}

// 下单成功后生成新的 boid
function updateBoid() {
    const newBoid = createId();
    setItem('boid', newBoid);
}

/**
 * 更新 url，新增 rc, bi_cf, ssid 等参数
 * @param {string} url
 * @param {string} rc
 * @returns {string}
 */
function updateUrl(url, rc) {
    const params = {
        ...getSessionExternal()
    };

    if(rc) {
        params.rc = rc
    }
    return qsUtil.update(url, params);
}


// 获取本地缓存的埋点信息
function getSession() {
    return {
        bi_cf: getItem('bi_cf'),
        ssid: getItem('ssid'),
        boid: getItem('boid'),
    };
}

// 获取外部跳转的埋点数据
function getSessionExternal() {
    const { bi_cf, ssid } = getSession();
    if (bi_cf) {
        return { bi_cf, ssid };
    }

    return { ssid };
}

export default {
    init,
    getSession,
    getSessionExternal,
    updateBoid,
    updateUrl
}
