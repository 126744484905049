import shareWX from './shareWX';
import model from './model';

import { utils } from '../mmcModule';

const obj = {};
/**
 * o.projuuid 必填
 * o.pageid 必填
 * o.shareRecord.scene 神策数据的场景值
 * o.title 分享标题
 * o.imgUrl 分享图片地址
 * o.desc 分享描述
 * o.success 回调方法
 * o.wbsuccess 微博分享接口请求成功后的回调，此时sdk不执行微博跳转操作
 *
 * 注：微博分享按钮 加上 .weibosharebtn 样式
 *    二维码分享按钮 加上 .qrcodesharebtn 样式
 */

let projuuid;


obj.init = function (o) {

    if (o.projuuid) {
        projuuid = o.projuuid;
        if (utils.is_weixin()) {
            model.getProjInfor(projuuid).then((res) => {
                if (res.code == 0) {
                    res = Object.assign(res, { sdkObj: o });
                    shareWX(res);

                }
            });
        }
    } else {
        alertMsg('分享参数错误');
    }
};



export default obj;
