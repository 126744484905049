/**
 * 自定义分析数据上报接口，模仿微信小程序的 wx.reportAnalytics() 的函数签名
 *
 * @param {string} eventName
 * @param {object} data
 * @param {number} numTries 失败后的尝试次数
 */
export default function reportAnalytics(eventName, data, numTries = 1000) {
    const sensors = window.sensors;

    if (sensors && sensors.track) {
        const extraParams = {
            $url: location.href,
            $referrer: document.referrer,
        };

        sensors.track(eventName, {
            ...extraParams,
            ...data,
        });
        return;
    }

    const numChances = numTries - 1;
    if (numChances <= 0) return;

    setTimeout(() => {
        reportAnalytics(eventName, data, numChances);
    }, 500);
}
