import wxShare from './wxShare';
import utils from '../utils';
// import wbShare from './wbShare';
// import qqShare from './qqShare';

const obj = {};

obj.init = function (o) {
    if (utils.is_weixin()) {
        wxShare.init(o);
    }
};

export default obj;
