import { utils } from '../mmcModule';

const params = utils.getRequestParams();

// 上次分享唯一标识
const prev_share_no = params.share_no ? params.share_no : '';
// 分享层级记数
const sharecount = params.sharecount && !isNaN(Number(params.sharecount)) ? Number(params.sharecount) : 0;
// 上一个分享者
const shareuuid = params.shareuuid ? params.shareuuid : '';
// 平台标识
const platform = params.platform ? params.platform : '';
// 上一次分享时间
const timestamp = params.timestamp ? params.timestamp : '';

const level = params.level && !isNaN(Number(params.level)) ? Number(params.level) : 0;

const mp = params.mp ? params.mp : '';

export default {
    prev_share_no,
    sharecount,
    shareuuid,
    platform,
    timestamp,
    level,
    mp,
};
