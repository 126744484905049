/**
 * 本文件用于分享项目详情页时的神策自定义埋点
 * 具体埋点需求文档参见：
 * https://qscwiki001.qingsongchou.com/pages/viewpage.action?pageId=39850569
 */

import reportAnalytics from '../reportAnalytics';
import curUrlParam from './curUrlParam';
import shareAction from './shareAction';
import saUtils from 'sensors-data-utils';

/**
 * 上报神策数据
 * @param {object} params
 * @param {number} params.shareto
 * @param {boolean} params.is_manager 是否主态
 * @param {string} params.project_uuid 当前项目的 uuid
 * @param {string} params.scene 分享场景，可能为空
 * @param {string} params.pageid 充当场景的备选值
 * @param {string} params.shareUrl 分享后的中间页的 URL
 */
export default function reportSaEvent(params) {
    if (!params) return;

    const {
        shareto,
        is_manager,
        scene,
        pageid,
        shareUrl,
        project_uuid = '',
    } = params;

    const {
        sharecount,
        mp,
    } = curUrlParam;

    const {
        share_no,
    } = shareAction;

    const finalSharecount = is_manager ? 0 : sharecount;

    const extraInfo = {
        shareurl: shareUrl,
        shareto: shareto + '',
        mp,
        ...saUtils.valueOf(),
    };

    reportAnalytics('H5_JSB_Share_Project', {
        FileName: scene || pageid || 'H5_JSB_Unknown_Page',
        FileValue: share_no,
        ExtraInfo: JSON.stringify(extraInfo),
        ExtraInfo1: finalSharecount + '',
        project_uuid,
    });
}
